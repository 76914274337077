
.face-pad-base {
	font-size: 1.875rem;
	font-weight: bold;
}

.face-pad-keyCap-host {
	position : relative;
	cursor: pointer;
	&:hover {
		color: #FCBC51;
		font-weight: bolder;
	}
}
.face-pad-keyCap {
	position: absolute;
	z-index: 100;
	left: 0.3rem;
	top: -0.1rem;
	border-radius: 4px;
	font-size: 1rem;
	font-weight: bolder;
	color: beige;
	font-family: 'courier new';
}

.next-num-card {
	color: #FCBC51;
}

.challenged {
	background-color: #2c4763;
	.count-down-label {
		font-size: 1.5rem;
		color: #FCBC51;
		font-weight: bold;
	}
}

.num-on-top {
	z-index: 200;
	background-color: black;
	color: #FCBC51;
	padding-left: 8px;
	padding-right: 8px;
}
