
.progress {
	border-radius : 6px;
	padding       : 6px;
}

.progress-bar {
	background-color    : #ee303c;
	border-radius       : 4px;
	transition          : 0.4s linear;
	transition-property : width, background-color;
}

.progress-striped .progress-bar {
	animation        : progressAnimationStrike 6s;
	background-color : #FCBC51;
	background-image : linear-gradient(
					45deg, rgb(252, 163, 17) 25%,
					transparent 25%, transparent 50%,
					rgb(252, 163, 17) 50%, rgb(252, 163, 17) 75%,
					transparent 75%, transparent);
	width            : 100%;
}

@keyframes progressAnimationStrike {
	from { width : 0 }
	to { width : 100% }
}

.progress2 {
	background    : rgba(0, 0, 0, 0.25);
	border-radius : 30px;
	box-shadow    : inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
	padding       : 6px;
}

.progress-bar2 {
	background-image    : linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
	border-radius       : 30px;
	height              : 18px;
	transition          : 0.4s linear;
	transition-property : width, background-color;
}

.progress-moved .progress-bar2 {
	animation        : progressAnimation 6s;
	background-color : #EF476F;
	width            : 85%;
}

@keyframes progressAnimation {
	0% { width : 5%; background-color : #F9BCCA;}
	100% { width : 85%; background-color : #EF476F; }
}

$green      : #4cd964;
$turquoise  : #5ac8fa;
$blue       : #007aff;
$light-blue : #7DC8E8;
$purple     : #5856d6;
$red        : #ff2d55;

.progress-bar3 {
	background-image    : linear-gradient(to right,
			$green, $turquoise, $blue,
			$light-blue, $purple, $red);
	border-radius       : 4px;
	transition          : 0.4s linear;
	transition-property : width, background-color;
}

.progress-infinite .progress-bar3 {
	animation        : colorAnimation 1s infinite;
	background-image : linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
	width            : 100%;
}


$yellow1    : #333300;
$yellow2    : #4c4c00;
$yellow3    : #808000;
$yellow4    : #cccc00;
$yellow5    : #ffff00;


@keyframes colorAnimation {
	0% {
		background-image : linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
	}
	20% {
		background-image : linear-gradient(to right, $turquoise, $blue, $light-blue, $purple, $red, $green);
	}
	40% {
		background-image : linear-gradient(to right, $blue, $light-blue, $purple, $red, $green, $turquoise);
	}
	60% {
		background-image : linear-gradient(to right, $light-blue, $purple, $red, $green, $turquoise, $blue);
	}
	100% {
		background-image : linear-gradient(to right, $purple, $red, $green, $turquoise, $blue, $light-blue);
	}
}


.progress-yellow {
	animation        : yellowShift 3s linear infinite;
	background-image : linear-gradient(to right, $yellow1, $yellow2, $yellow3, $yellow4, $yellow5);
	//background-image : linear-gradient(to right, $green, $turquoise, $blue, $light-blue, $purple, $red);
	width            : 100%;
}


@keyframes yellowShift {
	0% {
		background-image : linear-gradient(to right, $light-blue, $yellow2, $yellow3, $yellow4, $yellow5);
	}
	20% {
		background-image : linear-gradient(to right, $yellow1, $light-blue, $yellow3, $yellow4, $yellow5);
	}
	40% {
		background-image : linear-gradient(to right, $yellow1, $yellow2, $light-blue, $yellow4, $yellow5);
	}
	60% {
		background-image : linear-gradient(to right, $yellow1, $yellow2, $yellow3, $light-blue, $yellow5);
	}
	100% {
		background-image : linear-gradient(to right, $yellow1, $yellow2, $yellow3, $yellow4, $light-blue),;
	}
}

