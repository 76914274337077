/* https://flask-dashboard-atlantis-dark-pro.appseed.us/page-projects.html */

$main-5: #0e2439;
$main-4: #2c4763;
$main-3: #274059;
$main-2: #1f364d;
$main-1: #162d43;
$alt-1: #a2b8cd;
$alt-2: #63809c;
$alt-3: #9cb3c9;
$alt-4: #fff;
$warning: #f97920;
.text-warning {color: $warning;}
.text-main-5 {color: $main-5 ;}
.text-main-4 {color: $main-4 ;}
.text-main-3 {color: $main-3 ;}
.text-main-2 {color: $main-2 ;}
.text-main-1 {color: $main-1 ;}
.text-alt-1 {color: $alt-1 ;}
.text-alt-2 {color: $alt-2 ;}
.text-alt-3 {color: $alt-3 ;}
.text-alt-4 {color: $alt-4 ;}
.border-main-5 {border-color: $main-5 ;}
.border-main-4 {border-color: $main-4 ;}
.border-main-3 {border-color: $main-3 ;}
.border-main-2 {border-color: $main-2 ;}
.border-main-1 {border-color: $main-1 ;}
.border-alt-1 {border-color: $alt-1 ;}
.border-alt-2 {border-color: $alt-2 ;}
.border-alt-3 {border-color: $alt-3 ;}
.border-alt-4 {border-color: $alt-4 ;}
.hover-text-main-5:hover {color: $main-5 ;}
.hover-text-main-4:hover {color: $main-4 ;}
.hover-text-main-3:hover {color: $main-3 ;}
.hover-text-main-2:hover {color: $main-2 ;}
.hover-text-main-1:hover {color: $main-1 ;}
.hover-text-alt-1:hover {color: $alt-1 ;}
.hover-text-alt-2:hover {color: $alt-2 ;}
.hover-text-alt-3:hover {color: $alt-3 ;}
.hover-text-alt-4:hover {color: $alt-4 ;}
.bg-main-5 {background-color: $main-5 ;}
.bg-main-4 {background-color: $main-4 ;}
.bg-main-3 {background-color: $main-3 ;}
.bg-main-2 {background-color: $main-2 ;}
.bg-main-1 {background-color: $main-1 ;}
.bg-alt-1 {background-color: $alt-1 ;}
.bg-alt-2 {background-color: $alt-2 ;}
.bg-alt-3 {background-color: $alt-3 ;}
.bg-alt-4 {background-color: $alt-4 ;}
.hover-bg-main-5:hover {background-color: $main-5 ;}
.hover-bg-main-4:hover {background-color: $main-4 ;}
.hover-bg-main-3:hover {background-color: $main-3 ;}
.hover-bg-main-2:hover {background-color: $main-2 ;}
.hover-bg-main-1:hover {background-color: $main-1 ;}
.hover-bg-alt-1:hover {background-color: $alt-1 ;}
.hover-bg-alt-2:hover {background-color: $alt-2 ;}
.hover-bg-alt-3:hover {background-color: $alt-3 ;}
.hover-bg-alt-4:hover {background-color: $alt-4 ;}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: red;
	opacity: 1; /* Firefox */
}

.text-box
{
	border: none;
	outline: none;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABHklEQVQ4EaVTO26DQBD1ohQWaS2lg9JybZ+AK7hNwx2oIoVf4UPQ0Lj1FdKktevIpel8AKNUkDcWMxpgSaIEaTVv3sx7uztiTdu2s/98DywOw3Dued4Who/M2aIx5lZV1aEsy0+qiwHELyi+Ytl0PQ69SxAxkWIA4RMRTdNsKE59juMcuZd6xIAFeZ6fGCdJ8kY4y7KAuTRNGd7jyEBXsdOPE3a0QGPsniOnnYMO67LgSQN9T41F2QGrQRRFCwyzoIF2qyBuKKbcOgPXdVeY9rMWgNsjf9ccYesJhk3f5dYT1HX9gR0LLQR30TnjkUEcx2uIuS4RnI+aj6sJR0AM8AaumPaM/rRehyWhXqbFAA9kh3/8/NvHxAYGAsZ/il8IalkCLBfNVAAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: 16px 18px;
	background-position: 98% 50%;
	cursor: auto;
	background-color: $main-2;
	color: $alt-3;
	border-radius: 3px;
	font-size: 18px;
	padding: 14px;
	text-overflow: ellipsis;
	transition: border-width 120ms;
	width: 100%;
	&:focus
	{
		border-left: 3px solid #4799eb;
	}
	&::placeholder {
		color: $main-4
	}
}



$main: $main-2;
$alt: $alt-2;


$color-brand : #4476fd;


.bg-white {background-color : whitesmoke}

.bg-gray-100 {background-color : #F5F5F5}

.bg-gray-200 {background-color : #EEEEEE}

.bg-gray-300 {background-color : #F5F5F5}

.bg-gray-400 {background-color : #BDBDBD}

.bg-gray-600 {background-color : #616161}

.bg-gray-800 {background-color : #424242}

.bg-default {background-color : $main-2}

.bg-primary {background-color : $alt-2;}

$color-info : #272b32;
.bg-info {background-color : $color-info;}

$color-success : #7c8958;
.bg-success { background-color : $color-success}

.bg-warning {background-color : $warning}

.bg-danger {background-color : #f44336}

.bg-transparent {
	background-color : transparent;
}

.border-primary {
	border-color     : $alt-2;
	--border-opacity : 1;
}

.bg-main-2-to-5 {
	background-image: linear-gradient(to bottom, $main-2, $main-5);
}

.bg-main-3-to-5 {
	background-image: linear-gradient(to bottom, $main-2, $main-5);
}

hr.alt {
	border-top: 1px dashed $alt;
}
