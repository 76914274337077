.slide-door {
	min-width: 800px;
}

.slider-button {
	cursor          : pointer;
	display         : flex;
	filter          : brightness(120%);
	flex-direction  : column;
	justify-content : center;
	margin-bottom   : auto;
	margin-top      : auto;

	&:hover {
		color  : orange;
		filter : brightness(80%);
	}
}

.inactive-slide {
	display : none;
	//height  : 0;
}

.activating-slide {
	animation: bounceInRight; /* referring directly to the animation's @keyframe declaration */
	animation-duration: 2s; /* don't forget to set a duration! */
}
