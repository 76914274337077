// https://flask-dashboard-atlantis-dark-pro.appseed.us/page-projects.html
// https://mudwtr.com/collections/shop?
@import "color";
@import "font";

$font-brand: "Ubuntu Mono", sans-serif;
$font-base: "Ubuntu Mono";
$font-hand: "Kalam", cursive;
$font-serif: "Georgia";

body {
	//background-image: linear-gradient(to bottom, $main-2 , $main-5);
	background-color: $main-5;
	font-family: $font-base;
}

.full-height {
	min-height: 100vh;
}

.font-brand {
	font-family: $font-brand;
}

.font-hand {
	font-family: $font-hand;
}

.nav-bar {
}

.transition-1 {
	transition: 0.1s;
	transition-property: all;
}
.transition-3 {
	transition: 0.3s;
	transition-property: all;
}
.transition-5 {
	transition: 0.5s;
	transition-property: all;
}

nav {
	height: 56px;
	transition: 0.5s;
	transition-property: all;

	.nav-item {
		color: $alt-2;
		cursor: pointer;
		display: inline-flex;
		font-size: 1rem;
		font-weight: bold;
		letter-spacing: 0.1rem;

		&:hover {
			color: $alt-4;
		}

		&-selected {
			color: $alt-4;
			cursor: auto;
			font-weight: bold;

			&:hover {
				color: $alt-2;
			}
		}
	}
}

.card-grid {
	$x-gap: 2rem;
	$y-gap: 1rem;
	grid-column-gap: $x-gap;
	grid-row-gap: $y-gap;
	padding: $y-gap $x-gap $y-gap $x-gap;
}

.game-card {
	align-content: center;
	display: flex;
	flex-direction: column;
	font-family: $font-brand;
	height: 4rem;
	justify-content: center;
	transition: 0.5s;
	transition-property: all;
	width: 4rem;

	&-face {
		background-color: $alt-2;
		color: $main;
	}

	&-resolving {
		background-color: $alt;
		cursor: pointer;

		&-guessing {
			background-color: $alt-2;
		}

		&-challenged {
			background-color: black;
			color: yellow;
			font-size: 1.5rem;
		}
	}

	&-lost {
		background-image: url(../images/stripe.png);
	}

	&-back {
		background-color: $alt-2;
		color: $main;
	}

	&-5 {
		border-bottom-color: $alt-4;
		border-bottom-style: solid;
		border-width: 4px;
		border-top-style: none;
		border-left-style: none;
		border-right-style: none;
	}
}

.guess-card {
	border-color: $alt-2;
	border-style: dashed;
	border-width: thin;
	color: $alt;
	cursor: pointer;
	font-size: 2rem;
	font-weight: bolder;
	transition: 0.2s;
	transition-property: all;

	&-relative {
		position: relative;
	}

	&:hover {
		border-style: solid;
		border-width: thick;
		color: whitesmoke;
	}

	&-key {
		position: absolute;
		top: -20px;
		left: -8px;
		background: $main-1;
		border-radius: 4px;
		padding: 1px 8px 1px 8px;
		color: $alt-2;
		font-size: 1.4rem;
		font-family: 'courier new';
	}


}




.selector-pop {
	border-color: greenyellow;
	border-width: thick;
	left: -200px;
	position: relative;
}

.selector {
	$bg-unknown: #dd6b20;
	$bg-next: #f6ad55;
	padding: 16px;

	.unknown {
		background-color: $bg-unknown;
	}

	.pad {
		$width: 2rem;
		$height: 3rem;
		display: flex;
		flex-direction: column;
		font-size: 2rem;
		font-weight: 600;
		height: $height;
		justify-content: center;
		text-align: center;
		width: $width;
	}

	.choice {
		background-color: $bg-unknown;
		border-radius: 0.2rem;
		color: dimgray;
		cursor: pointer;
		font-weight: 400;

		&:hover {
			background-color: $bg-unknown;
			color: #feebc8;
		}
	}

	.next {
		background-color: $bg-next;
	}

	.left {
		padding-right: 16px;
	}

	.right {
	}
}

.game-board {
	background-color: #2e2e2e;
	padding: 5rem;
}

.btn {
	border-radius: 2px;
	transition: 0.5s;
	transition-property: all;

	&:hover {
		background-color: $alt;
	}

	&:active {
		background-color: black;
		color: whitesmoke;
	}

	&-selected {
		background-color: $main-3;
		color: whitesmoke !important;
		transition: 0.1s;
		transition-property: all;
	}

	&-plain {
		color: $main;

		&:hover {
			color: whitesmoke;
		}

		&:active {
			background-color: transparent;
			color: $main-2;
		}
	}

	&-disabled {
		background-color: gray !important;
		color: lightgray !important;
	}


	&-color {
		background-image: linear-gradient(to right, #e052a0, #f15c41);
		color: white;

		&:hover {
			background-image: linear-gradient(to right, #3ec7e0, #526bf4);
		}
	}

	&-link {
		color: $alt-2;

		&:hover {
			background-color: inherit;
			color: whitesmoke;
		}

		&:active {
			background-color: transparent;
			color: $alt-2;
		}
	}
}

.login-panel {
	.border-t {
		border-top-width: 8px;
	}
}

.spacing-2 {
	letter-spacing: 2px;
}

.spacing-3 {
	letter-spacing: 3px;
}

.spacing-normal {
	letter-spacing: normal;
}

.square-2 {
	font-size: 1.2rem;
	font-weight: bolder;
	height: 2.2rem;
	width: 2.2rem;
}

.modal {
	align-content: center;
	background-color: rgba(2, 2, 2, 0.85);
	display: grid;
	grid-auto-flow: column;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	transition: 2s;
	transition-property: all;
	z-index: 1000;

	&-up-100 {
		position: relative;
		top: -100px;
	}

	&-inner {
	}

	&-hidden {
		display: none;
	}

	&-show {
		height: 100vh;
		width: 100vw;
	}
}

.uncut {
	border: #555;
	border-style: solid;
	border-width: 0.1rem;
	max-height: 50vh;
	width: auto;
}

.profile {
	&-image {
		border-color: $alt-2;
		border-style: solid;
		border-width: 0.5rem;
		width: 196px;
	}

	&-image-display {
		border-color: transparent;
		border-style: solid;
		border-width: 0.2rem;
		border-radius: 1rem;
	}

	&-image-edit {
		border-color: $main-4;
		border-style: dashed;
		border-width: 0.2rem;

		&:hover {
			border-color: $alt-2;
			border-style: dashed;
			cursor: pointer;
		}
	}
}

.photo-uploader-icon {
	height: 0;
	left: 140px;
	position: relative;
	top: -140px;
	z-index: 20;
}

.score {
	.label {
		background-color: $main-3;
		border-radius: 4px;
		font-size: 0.75rem;
		font-weight: bold;
		padding: 4px;
		margin-top: auto;
		margin-bottom: auto
	}

	.number {
		color: lightgreen;
		font-size: 2rem;
	}

	.time {
		font-size: 2rem;
		padding-right: 8px;
	}
}

.mark-background {
	background-color: #dd6b20;
}

.hero-title {
	color: $alt-4;
	font-size: 3rem;
	font-weight: lighter;
	letter-spacing: 2px;
}

.origin {
	color: $alt-3;
	font-size: 1.2rem;
	font-weight: 200;
}

.origin-badge {
	background-color: $main-4;
	border-radius: 4px;
	color: $alt-2;
	font-size: 1.1rem;
	margin-right: 8px;
	padding: 1px 8px 1px 8px;
}


.status-menu {
	background-color: $alt-2;
	.menu-option {
	  color: $main-3; 
	  &:hover{
		  color: $alt-1;
	  }   
	}
  }


.rotate {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

.networking {
	animation: blinking 2s infinite ease-in-out;
}

@keyframes blinking {
	from { background-color: $alt-1; }
	to { background-color: $main-4; }
}

.whitespace-no-wrap {
	white-space: nowrap;
}

.w-48rem {width: 48rem}
.w-32rem {width: 32rem}
.w-42rem {width: 42rem}
.w-24rem {width: 24rem}

.rotated-90 {
	transform: rotate(90deg);
}

.point-left-down {
	transform: scaleX(-1) rotate(90deg);;
}

.bottom-dashed {
	border-bottom: 1px dashed $alt-2;
}